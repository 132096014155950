import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Grid,
    Typography,
    CardContent,
    Card,
    DialogContent,
    Dialog,
    DialogActions,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        width: "100%",
        height: "100%",
    },
    cardContainer: {
        height: "100%",
    },
    productCard: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: theme.spacing(2),
        backgroundColor: "#f4f7fe",
        boxShadow: "none",
    },
    issueCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: theme.spacing(2),
        backgroundColor: "#f4f7fe",
        boxShadow: "none",
    },
    issueImage: {
        width: 75,
        marginRight: theme.spacing(2),
    },

    button: {
        marginLeft: "auto",
        fontSize: "1rem",
        "&:hover": {
            backgroundColor: "var(--main-color)",
        },
        backgroundColor: "var(--main-color)",
        color: "white",
    },
    dialogPaperFullScreen: {
        width: "90%",
        height: "90%",
        borderRadius: "1.5rem",
    },
}));

const IssuesDialogBox = ({ open, onClose, onSubmit, issues }) => {
    const classes = useStyles();
    const [totalAmount, setTotalAmount] = useState(0);
    const [issuesToResolve, setIssuesToResolve] = useState([]);
    const [issuesToShow, setIssuesToShow] = useState([...issues]);
    const addIssues = (index) => () => {
        const issue = issuesToShow[index];
        if (!issue?.added || issue.added === false) {
            const { image, offer_price, issue_image, ...rest } = issue;
            issuesToShow.splice(index, 1);
            issuesToShow.splice(index, 0, { ...issue, added: true });
            setIssuesToResolve([...issuesToResolve, rest]);
            setIssuesToShow([...issuesToShow]);
            setTotalAmount((prev) => prev + issue.offer_price);
        } else {
            issue.added = false;
            setIssuesToShow([...issuesToShow]);
            setTotalAmount((prev) => prev - issue.offer_price);
            setIssuesToResolve([
                ...issuesToResolve.filter((value) => value.id !== issue.id),
            ]);
        }
    };
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            classes={{ paperFullScreen: classes.dialogPaperFullScreen }}
        >
            <DialogContent className={classes.root}>
                <div className={classes.cardContainer}>
                    <Card className={classes.productCard}>
                        <img
                            src="left-img.png"
                            alt="mobile-img"
                            className={classes.issueImage}
                        />
                        <Typography
                            variant="h4"
                            style={{
                                fontFamily: "Titillium Web, sans-serif",
                                fontWeight: "bold",
                            }}
                        >
                            Product Name
                        </Typography>
                    </Card>
                    <Card className={classes.issueCard}>
                        <Typography
                            variant="h5"
                            style={{
                                fontFamily: "Titillium Web, sans-serif",
                                fontSize: "2rem",
                                margin: "2rem 0rem",
                            }}
                        >
                            Pick Your Repair Service
                        </Typography>
                        <Grid container spacing={2}>
                            {issuesToShow.map((issue, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    key={issue.issue_id}
                                >
                                    <Card>
                                        <CardContent
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src={issue.issue_image}
                                                    alt={issue.issue_name}
                                                    className={
                                                        classes.issueImage
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "0.3rem",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{
                                                            fontFamily:
                                                                "Titillium Web, sans-serif",
                                                            fontSize: "1.5rem",
                                                        }}
                                                    >
                                                        {issue.issue_name}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            fontFamily:
                                                                "Titillium Web, sans-serif",
                                                            fontSize: "1.5rem",
                                                            textDecoration:
                                                                "line-through",
                                                        }}
                                                    >
                                                        ₹{issue.org_price}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        style={{
                                                            fontFamily:
                                                                "Titillium Web, sans-serif",
                                                            fontSize: "1.5rem",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        ₹{issue.offer_price}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    className={classes.button}
                                                    onClick={addIssues(index)}
                                                >
                                                    {issue?.added === undefined
                                                        ? "Add"
                                                        : issue.added
                                                        ? "Remove"
                                                        : "Add"}
                                                </Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Card>
                </div>
            </DialogContent>
            <DialogActions>
                {totalAmount > 0 && (
                    <>
                        <Typography variant="h4" style={{ fontWeight: 600 }}>
                            Total: {totalAmount}
                        </Typography>
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => onSubmit(issuesToResolve)}
                        >
                            Proceed
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default IssuesDialogBox;
